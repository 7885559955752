import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaStar, FaStarHalfAlt, FaRegStar, FaTrashAlt, FaHistory, FaAmazon, FaEbay } from 'react-icons/fa';
import { db, collection, getDocs, deleteDoc, setDoc, getDoc, doc } from './firebase';
import { TbBrandWalmart  } from "react-icons/tb";
import './App.css';
import keys from './keys';
import 'tailwindcss/tailwind.css';
import stringSimilarity from 'string-similarity';




const renderStars = (rating) => {
  const maxStars = 5;
  const fullStars = Math.floor(rating);
  const halfStar = rating % 1 >= 0.25;
  const emptyStars = maxStars - fullStars - (halfStar ? 1 : 0);

  return (
    <div className="flex items-center">
      <span className="text-sm mr-1">{rating.toFixed(1)}</span>
      {Array(fullStars).fill(<FaStar className="text-yellow-500 text-xl" />)}
      {halfStar && <FaStarHalfAlt className="text-yellow-500 text-xl" />}
      {Array(emptyStars).fill(<FaRegStar className="text-yellow-500 text-xl" />)}
    </div>
  );
};

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [topItems, setTopItems] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [descriptions, setDescriptions] = useState({});
  const [typingItem, setTypingItem] = useState(null);
  const [showHistoryPopup, setShowHistoryPopup] = useState(false);

  useEffect(() => {
    const fetchSearchHistory = async () => {
      const historyCollection = collection(db, 'searchResults');
      const historySnapshot = await getDocs(historyCollection);
      const historyList = historySnapshot.docs.map(doc => doc.data().term);
      setSearchHistory(historyList);
    };

    fetchSearchHistory();
  }, []);

  useEffect(() => {
    const fetchDescriptions = async () => {
      for (const item of topItems) {
        if (!descriptions[item.name]) {
          const description = await generateDescription(item.name);
          setTypingItem(item.name); 
          simulateTyping(item.name, description);
        }
      }
    };

    if (topItems.length > 0) {
      fetchDescriptions();
    }
  }, [topItems]);

  const simulateTyping = (itemName, description) => {
    const typingSpeed = 10;
    let index = 0;
    const intervalId = setInterval(() => {
      setDescriptions(prevDescriptions => ({
        ...prevDescriptions,
        [itemName]: description.slice(0, index + 1)
      }));
      index++;
      if (index >= description.length) {
        clearInterval(intervalId);
      }
    }, typingSpeed);
  };

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const parseItemDetails = (itemText) => {
    const parts = itemText.split(':');
    const name = parts[0].trim();
    const [proPart, conPart, starsPart] = parts[1].split(', ').map(part => part.split(' - ')[1].trim());

    const stars = parseFloat(starsPart.split('/')[0].trim());
    
    return { name, pro: proPart, con: conPart, stars };
  };

  const generateDescription = async (item) => {
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Generate a product description for: ${item}. Full sentences only in 20-30 words please.` }
          ],
          max_tokens: 50,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${keys.OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );

      const description = response.data.choices[0].message.content;
      return description;
    } catch (err) {
      console.error('Error generating description from OpenAI:', err);
      return 'No description available';
    }
  };

  const saveSearchResults = async (term, items) => {
    try {
      const docRef = doc(db, 'searchResults', term);
      await setDoc(docRef, { term, items });
    } catch (err) {
      console.error('Error saving search results:', err);
    }
  };

  const fetchSavedResults = async (term) => {
    try {
      const historyCollection = collection(db, 'searchResults');
      const historySnapshot = await getDocs(historyCollection);
      const historyItems = historySnapshot.docs.map(doc => doc.data());
  
      const results = historyItems.map(item => ({
        ...item,
        similarity: stringSimilarity.compareTwoStrings(term.toLowerCase(), item.term.toLowerCase())
      }));
  
      const bestMatches = results.filter(result => result.similarity > 0.75);
  
      if (bestMatches.length > 0) {
        return bestMatches[0].items;
      }
  
      return null;
    } catch (err) {
      console.error('Error fetching saved results:', err);
      return null;
    }
  };

  
  const handleSearch = async () => {
    console.time('handleSearch');
    setLoading(true);
    setError('');
    setTopItems([]);
    setDescriptions({});
    setTypingItem(null);
  
    try {
      // step 1:  check AWS OpenSearch backend for synonyms
      //const synonymResponse = await axios.post('http://3.92.3.29:5000/search', { term: searchTerm });
      const synonymResponse = await axios.post('https://search.versatileai.net/api/search', { term: searchTerm });

      const synonyms = synonymResponse.data;
      console.log(synonyms);
  
      let termsToSearch = [searchTerm]; 
  
      // if synonyms are found, take the highest score's synonyms and add them to the search terms
      if (synonyms && typeof synonyms === 'object' && Object.keys(synonyms).length > 0) {
        // case where the response is not "No synonyms found"
        if (!synonyms.message) {
          const highestScore = Math.max(...Object.keys(synonyms).map(Number));
          termsToSearch = [...termsToSearch, ...synonyms[highestScore]];
        }
      } else {
        console.log("No synonyms found, proceeding to new search.");
      }
  
      // step  2: check Firebase for any of these terms
      let firebaseResults = null;
      for (const term of termsToSearch) {
        firebaseResults = await fetchSavedResults(term);
        if (firebaseResults) break; 
      }
  
      // if firebase results are found, display them
      if (firebaseResults) {
        setTopItems(firebaseResults);
        const descriptionsMap = firebaseResults.reduce((acc, item) => {
          acc[item.name] = item.description || 'No description available';
          return acc;
        }, {});
        setDescriptions(descriptionsMap);
        setLoading(false);
        console.timeEnd('handleSearch');
        return;
      }
  
      // step  3: If no synonyms or Firebase results, fall back to OpenAI GPT
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Give me the top 10 ${searchTerm} in a numbered list. 
              Give one pro and one con for each 10 please. Find a star review for each as well. 
              Full sentences only in 400 tokens. Format should be: 1. <item name>: pros -, con -, stars -.
              Each number should only take up one line, with everything in the same line.` },
          ],
          max_tokens: 400,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${keys.OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const text = response.data.choices[0].message.content;
      const listItems = text.match(/(\d+\.\s[^\n]+)/g) || [];
  
      const parsedItems = await Promise.all(listItems.map(async (item) => {
        const details = parseItemDetails(item.replace(/^\d+\.\s/, ''));
        const description = await generateDescription(details.name);
        return { ...details, description };
      }));
  
      if (parsedItems.length > 0) {
        setTopItems(parsedItems.slice(0, 10));
        await saveSearchResults(searchTerm, parsedItems.slice(0, 10));
        setSearchHistory(prevHistory => [...new Set([searchTerm, ...prevHistory])]);
      }
  
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
    } finally {
      setLoading(false);
      console.timeEnd('handleSearch');
    }
  };

  const simpleSearch = async () => {
    console.time('handleSearch');
    setLoading(true);
    setError('');
    setTopItems([]);
    setDescriptions({});
    setTypingItem(null);
  
    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful assistant.' },
            { role: 'user', content: `Give me the top 10 ${searchTerm} in a numbered list. 
              Give one pro and one con for each 10 please. Find a star review for each as well. 
              Full sentences only in 400 tokens. Format should be: 1. <item name>: pros -, con -, stars -.
              Each number should only take up one line, with everything in the same line.` },
          ],
          max_tokens: 400,
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${keys.OPENAI_API_KEY}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      const text = response.data.choices[0].message.content;
      const listItems = text.match(/(\d+\.\s[^\n]+)/g) || [];
  
      const parsedItems = await Promise.all(listItems.map(async (item) => {
        const details = parseItemDetails(item.replace(/^\d+\.\s/, ''));
        const description = await generateDescription(details.name);
        return { ...details, description };
      }));
  
      if (parsedItems.length > 0) {
        setTopItems(parsedItems.slice(0, 10));
        await saveSearchResults(searchTerm, parsedItems.slice(0, 10));
        setSearchHistory(prevHistory => [...new Set([searchTerm, ...prevHistory])]);
      }
  
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error fetching data');
    } finally {
      setLoading(false);
      console.timeEnd('handleSearch');
    }
  };


  const handleHistoryClick = async (term) => {
    const savedResults = await fetchSavedResults(term);
    setTopItems(savedResults);

    const descriptionsMap = savedResults.reduce((acc, item) => {
      acc[item.name] = item.description || 'No description available';
      return acc;
    }, {});
    setDescriptions(descriptionsMap);
  };

  const handleDeleteHistory = async () => {
    try {
      const historyCollection = collection(db, 'searchResults');
      const historySnapshot = await getDocs(historyCollection);
      const deletePromises = historySnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePromises);
      setSearchHistory([]);
    } catch (err) {
      console.error('Error deleting history from Firestore:', err);
      setError('Error deleting history from Firestore');
    }
  };

  
  const handleHistoryButtonClick = () => {
    setShowHistoryPopup(true);
  };

  const handleClosePopup = () => {
    setShowHistoryPopup(false);
  };


  return (
    <div className="App min-h-screen flex flex-col items-center justify-center relative">
      <h1 className="text-3xl font-bold mb-4">Versatile Top 10</h1>
      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          className="border rounded-lg p-2 flex-grow"
          style={{ minWidth: '700px' }}
        />
        <button
          onClick={handleSearch}
          disabled={loading}
          className="bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 text-white rounded-lg p-2 ml-2 hover:scale-105 transform transition-transform duration-300 disabled:bg-blue-300"
        >
          {loading ? 'Searching...' : 'Search'}
        </button>
        <button
          onClick={handleHistoryButtonClick}
          className="bg-gray-500 text-white p-2 rounded-lg ml-2"
        >
          <FaHistory />
        </button>
      </div>
  
      {error && <p className="text-red-500 mt-4">{error}</p>}
  
      <div className="flex flex-col w-full max-w-screen-lg mt-4">
        {topItems.map((item, index) => (
          <div key={index} className="mb-6 p-4 bg-tan rounded-lg shadow-lg relative">
            <div className="flex flex-col">
              <div className="flex items-start mb-2">
                <div className="flex-grow">
                  <h2 className="text-2xl font-bold mb-1 text-left">
                    {item.name}
                  </h2>
                  {renderStars(item.stars)}
                  <div className="text-sm text-gray-700 flex items-start mt-2">
                    <div className="mr-4">
                      <p className="mb-1">Pro: {item.pro}</p>
                      <p>Con: {item.con}</p>
                    </div>
                  </div>
                </div>
              </div>
              {descriptions[item.name] ? (
                <p className="text-gray-700">{descriptions[item.name]}</p>
              ) : (
                <div className="flex items-center">
                  <span>Loading description</span>
                  <div className="w-4 h-4 border-t-2 border-blue-600 border-solid rounded-full animate-spin ml-2"></div>
                </div>
              )}
              <div className="absolute top-4 right-4 flex space-x-2">
                {/* Amazon Link */}
                <a
                  href={`https://www.amazon.com/s?k=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#FF9900', color: 'white' }}
                >
                  <FaAmazon className="text-gray-800 text-xl" />
                  <span className="ml-2 text-gray-800">Buy on Amazon</span>
                </a>
  
                {/* eBay Link */}
                <a
                  href={`https://www.ebay.com/sch/i.html?_nkw=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#0064D2', color: 'white' }}
                >
                  <FaEbay className="text-white-800 text-xl" />
                  <span className="ml-2 text-white-800">Buy on eBay</span>
                </a>
  
                {/* Walmart Link */}
                <a
                  href={`https://www.walmart.com/search/?query=${item.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center bg-white shadow-md rounded-lg p-2 hover:scale-105 transition-transform duration-300"
                  style={{ backgroundColor: '#0071CE' }}
                >
                  <TbBrandWalmart className="text-xl" style={{ color: '#FFD700' }} />
                  <span className="ml-2" style={{ color: '#FFD700' }}>Buy on Walmart</span>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
  
      {/* History Popup */}
      {showHistoryPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-1/2 relative">
            <div className="flex items-center justify-between mb-2">
              <h2 className="text-xl font-semibold">Search History</h2>
              <div className="absolute top-2 right-2 flex items-center">
                <button
                  onClick={handleDeleteHistory}
                  className="bg-red-500 text-white p-2 rounded-lg mr-2"
                >
                  <FaTrashAlt />
                </button>
                <button
                  onClick={handleClosePopup}
                  className="text-gray-500"
                >
                  &times;
                </button>
              </div>
            </div>
            {searchHistory.length > 0 ? (
              <ul>
                {searchHistory.map((term, index) => (
                  <li key={index} className="mb-2 cursor-pointer text-blue-600 underline" onClick={() => handleHistoryClick(term)}>
                    {term}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No search history</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
  
};  

export default App;
